import React from 'react'
import { Link } from 'react-router-dom'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

export default function Sidebar() {

    let navigate = useNavigate()

    const user = JSON.parse(localStorage.getItem('user'))

    const logout = () => {
        localStorage.removeItem('user')
        window.location.replace('/')
    }

    return (
        <div class="left-side-menu">

                <div class="slimscroll-menu">

                    <div class="user-box text-center">
                        <img src={user.profile_photo_url} alt="user-img" title="Mat Helme" class="rounded-circle img-thumbnail avatar-md" />
                        <div class="dropdown">
                            <a href="#" class="user-name dropdown-toggle h5 mt-2 mb-1 d-block" data-toggle="dropdown"  aria-expanded="false">{user.name}</a>
                            <div class="dropdown-menu user-pro-dropdown">

                                <a href="javascript:void(0);" class="dropdown-item notify-item">
                                    <i class="fe-user mr-1"></i>
                                    <span>My Account</span>
                                </a>
    
                                <a href="javascript:void(0);" class="dropdown-item notify-item">
                                    <i class="fe-settings mr-1"></i>
                                    <span>Settings</span>
                                </a>
    
                                <a href="javascript:void(0);" class="dropdown-item notify-item">
                                    <i class="fe-lock mr-1"></i>
                                    <span>Lock Screen</span>
                                </a>
    
                                <a href="javascript:void(0);" class="dropdown-item notify-item">
                                    <i class="fe-log-out mr-1"></i>
                                    <span>Logout</span>
                                </a>
    
                            </div>
                        </div>
                        <p class="text-muted">{user.role.name}</p>
                    </div>

                    <div id="sidebar-menu">

                        <ul class="metismenu" id="side-menu">

                            <li class="menu-title">Navigation</li>

                            <li>
                                <Link to={'/'}>
                                    <i class="mdi mdi-view-dashboard"></i>
                                    <span> Dashboard </span>
                                </Link>
                            </li>
                            <li>
                                <Link to={`/dashboard/${JSON.parse(localStorage.getItem('user')).id}`}>
                                    <i class="fe-user"></i>
                                    <span> Profile </span>
                                </Link>
                            </li>
                            <li>
                                <Link to={'/laporan'}>
                                    <i class="fe-trending-up"></i>
                                    <span> Laporan </span>
                                </Link>
                            </li>

                            <li>
                                <a style={{cursor: 'pointer'}} onClick={logout}>
                                    <i class="fe-log-out"></i>
                                    <span> Logout </span>
                                </a>
                            </li>

                        </ul>

                    </div>

                    <div class="clearfix"></div>

                </div>

            </div>
    )
}
