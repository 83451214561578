import InfoCard2 from '../components/atoms/InfoCard2'
import ChartDashboard from '../components/atoms/ChartDashboard'
import axios from 'axios'
import React, { Component } from 'react'
import Donut from '../components/atoms/Donut'
import { Table } from 'react-bootstrap'
import HeaderCard from '../components/atoms/HeaderCard'
import Profile from '../components/atoms/Profile'
import TaskAssignCard from '../components/atoms/TaskAssignCard'
import DatePicker from "react-multi-date-picker";
import TaskThisDay from '../components/atoms/TaskThisDay'
import TaskUrgent from './TaskUrgent'
import io from 'socket.io-client'
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';


export default class UserDashboard extends Component {

    constructor(props){
        super(props)
        this.handleWIP = this.handleWIP.bind(this)
        this.handleUrgent = this.handleUrgent.bind(this)
        this.handleCompleted = this.handleCompleted.bind(this)
    }

    state={
        thismonth:"",
        completed:0,
        completed2:0,
        urgentTask : [],
        pending:"",
        thisday: "",
        task: [{
            name:""
        }],
        month:"",
        year: "",
        brandLabel: [],
        brandCount: [],
        release: 0,
        user: {
            name: "",
            img: "",
            role: {
                name: ""
            },
            tasks: []
        },
        value: new Date(),
        taskday: [],
        date: "",
        date2: "",
        taskRelease: [],
        selesai: 0,
        wip: 0,
        rilis: 0,
        isVisible2: false,
    }

    getData = async () => {
        const d = new Date();
        let year = d.getFullYear();
        let day = this.pad(d.getDate());
        const month = this.pad(d.getMonth()+1);
        // console.log(year + " " + month)
        const result = await axios.get(`https://apidigimark.sandigroup.id/api/user?id=${this.props.id}&month=${month}&year=${year}&day=${day}`)
        const urgentTask = []
        const daytask = []
        result.data.user.tasks.map(e => {
            daytask.push(e.start_date.substr(-2))
        })
        console.log(result.data.user.tasks)
        const count = {};
        daytask.forEach(function(i) { count[i] = (count[i]||0) + 1;});
        this.setState({
            thisday : result.data.thisday,
            task: result.data.user.tasks,
            thismonth: result.data.user.tasks.length,
            user: {
                name: result.data.user.name,
                img: result.data.user.profile_photo_path || result.data.user.profile_photo_url,
                role : result.data.user.role
            },
            brandCount: Object.values(count),
            brandLabel: Object.keys(count)
        })
        this.getChart()
    }
    getChart = async () =>  {
        const d = new Date();
        let year = d.getFullYear();
        let day = this.pad(d.getDate());
        const month = this.pad(d.getMonth()+1);
        const result = await axios.get(`https://apidigimark.sandigroup.id/api/user?id=${this.props.id}&month=${month}&year=${year}&day=${day}`)
        console.log(result.data)
        if(result){
            this.setState({
                selesai: result.data.completed,
                rilis: result.data.release,
                wip: result.data.wip
            })
        }
    }
    getSpecificData = async (year, month) => {
        // console.log(year + " " + month)
        const result = await axios.get(`https://apidigimark.sandigroup.id/api/task?year=${year}&month=${month}&dashboard=true`)
        const data = Object.values(result.data)
        const series = []
        const uniqueCount = result.data.taskbrand
        const count = {};
        uniqueCount.forEach(function(i) { count[i] = (count[i]||0) + 1;})
        if(result.data.taskbrand.length == 0){
            alert('Data tidak ditemukan')
        }else{
            data.map(x => {
                series.push(parseInt(x))
            })
            this.setState({
                thismonth: result.data.thismonth,
                completed: result.data.completed,
                release: result.data.release,
                pending: result.data.pending,
                task: result.data.task,
                brandCount: Object.values(count),
                brandLabel: Object.keys(count)
            })
        } 
    }

    componentDidMount = () => {
        const d = new Date();
        let year = d.getFullYear();
        let day = this.pad(d.getDate());
        const month = this.pad(d.getMonth()+1);
        const date = year + "-" + month + "-" + day
        const date2 = year + "-" + month + "-"
        this.getData()
        this.getTaskDay(date)
        this.getTaskRelease(date2, 'Release')
    }

    getTaskDay = async (data) => {
        const d = new Date();
        let year = d.getFullYear();
        let day = this.pad(d.getDate());
        const month = this.pad(d.getMonth()+1);
        const date = year + "-" + month + "-" + day
        const date2 = year + "-" + month + "-"
        const value = await axios.get(`https://apidigimark.sandigroup.id/api/taskuser?date=${data}&id=${this.props.id}`)
        this.setState({
            taskday : value.data,
            date: date,
            date2: date2
        })
    }

    getTaskRelease = async (data, status) => {
        const value = await axios.get(`https://apidigimark.sandigroup.id/api/taskuser?date=${data}&id=${this.props.id}&status=${status}`)
        this.setState({
            taskRelease: []
        })
        this.setState({
            taskRelease: value.data
        })
    }

    pad = (d) => {
        return (d < 10) ? '0' + d.toString() : d.toString();
    }

    handleWIP = async (id) => {
        const value = await axios.get(`https://apidigimark.sandigroup.id/api/assign?status=Work%20In%20Progress&id=${id}`)
        this.getData()
        this.getTaskDay(this.state.date)
        this.getTaskRelease(this.state.date2, 'Release')
    }
    handleUrgent = async (id) => {
        const value = await axios.get(`https://apidigimark.sandigroup.id/api/assign?status=Urgent%20Completed&id=${id}`)
        this.getData()
    }
    handleCompleted = async (id) => {

        const value = await axios.get(`https://apidigimark.sandigroup.id/api/assign?status=Completed&id=${id}`)
        this.getData()
    }

    handleChangeMonth = (e) => {
        this.setState({
            month: e.target.value
        })
    }
    handleChangeYear = (e) => {
        this.setState({
            year: e.target.value
        })
    }
    
    componentWillUnmount = () => {
        localStorage.setItem('data', JSON.stringify(this.state));
    }

    componentWillMount = () => {
        this.setState(JSON.parse(localStorage.getItem('data')))
    }



    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-lg-12">
                    <div className="chart-card2 mt-3">
                    <HeaderCard name={this.state.user.name}/>
                </div>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-lg-8">
                        <div className="row mt-2">
                        <div className="col-lg-6 mb-2">
                        <Donut options={{labels: ['Belum dikerjakan','Sudah dikerjakan','Sedang dikerjakan']}} series={[this.state.rilis,this.state.selesai, this.state.wip]}/>
                        </div>
                        <div className="col-lg-6">
                        <InfoCard2 color="color-blue" title="Total Task" subtitle="Total task bulan ini" number={this.state.thismonth} />
                        <InfoCard2 color="color-green" title="Task Hari Ini" subtitle="Total task hari ini" number={this.state.thisday} />
                        <InfoCard2 color="color-red" title="Belum Dikerjakan" subtitle="Total task belum di kerjakan" number={this.state.release} />
                        </div>
                        <div className="col-lg-6">
                        <div className='chart-card'>
                                <div className="header-card">
                                    <div className="left">
                                        <div className="left-bar2"></div>
                                        <div className="title">Regular Task</div>
                                    </div>
                                    <div className="right">
                                        <div className="status2"></div>
                                        In Proggress
                                    </div>
                                </div>
                                {this.state.task.length == 0? <TaskAssignCard isempty={true} /> : this.state.task.map(e => {
                                   return e.status == "Work In Progress" ? <TaskAssignCard name={e.name} handler={()=>{
                                       this.handleCompleted(e.id)
                                   }} tgl={e.start_date} /> : null
                                })}
                            </div>
                        {/* <div className="chart-card">
                        <Donut options={{labels: ['Task belum dikerjakan','Task sudah dikerjakan', 'Pending']}} series={[this.state.release,this.state.completed, this.state.pending]}/>
                        </div> */}
                        </div>
                        <div className="col-lg-6">
                            <div className='chart-card'>
                                <div className="header-card">
                                    <div className="left">
                                        <div className="left-bar"></div>
                                        <div className="title">Urgent Task</div>
                                    </div>
                                    <div className="right">
                                        <div className="status"></div>
                                        In Proggress <button className='btn btn-sm btn-rounded btn-danger ml-2' onClick={()=>{
                                            this.setState({
                                                isVisible2: true
                                            })
                                        }}><i className='fas fa-plus'></i></button>
                                    </div>
                                </div>
                                {this.state.task.length == 0? <TaskAssignCard isempty={true} /> : this.state.task.map(e => {
                                   return e.status == "Urgent" ? <TaskAssignCard  name={e.name} handler={()=>{
                                       this.handleUrgent(e.id)
                                   }} tgl={e.start_date} /> : null
                                })}
                            </div>
                        </div>
                       
                        </div>
                    </div>
                    <div className="col-lg-4">
                    <div className='chart-card mt-2'>
                            <div className="header-card">
                                <div className="left">
                                    <div className="left-bar3"></div>
                                    <div className="title">Task Hari Ini</div>
                                </div>
                                <div className="right">
                                    <DatePicker value={this.state.value} onChange={(e) => {
                                        const day = this.pad(e.day)
                                        const year = e.year
                                        const month = this.pad(e.monthIndex+1)
                                        const date = year + "-" + month + "-" + day
                                        this.setState({
                                            date: date
                                        })
                                        this.getTaskDay(date)
                                    }} />
                                </div>
                            </div>
                            {this.state.taskday.length == 0? <TaskAssignCard isempty={true} /> : this.state.taskday.map(e => {
                                                return e.status == "Release" ? <TaskAssignCard id={e.id} handler={()=>{
                                                    this.handleWIP(e.id)
                                                }} name={e.name} brand={e.brand.name} tgl={e.start_date} status={e.status} /> : null
                                                })}
                    </div>
                    <div className='chart-card mt-2'>
                            <div className="header-card">
                                <div className="left">
                                    <div className="left-bar3"></div>
                                    <div className="title">Task Belum Di Kerjakan</div>
                                </div>
                            </div>
                            {this.state.taskRelease.length == 0? <TaskAssignCard isempty={true} /> : this.state.taskRelease.slice(0, 5).map(e => {
                                                return e.status == "Release" ? <TaskAssignCard id={e.id} handler={()=>{
                                                    this.handleWIP(e.id)
                                                }} name={e.name} tgl={e.start_date} brand={e.brand.name} /> : null
                                                })}
                    </div>
                    </div>
                </div>
                {this.state.isVisible2 ? <TaskUrgent buttonPress={()=>{
                    this.getData()
                }} userid={this.props.id}  handler={()=>{
                    this.setState({
                        isVisible2: false
                    })
                }}/> : null}
            </div>
        )
    }
}
