import React, { useRef } from 'react'
import { useState, useEffect } from 'react'
import io from 'socket.io-client'
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';

const socket = io.connect('http://localhost:4000')

export default function TestChat() {
    const [ state, setState ] = useState({ message: "Halo", name: "Asu" })
	const [ chat, setChat ] = useState([])

	const socketRef = useRef()

	useEffect(
		() => {
			socketRef.current = io.connect("http://localhost:4000")
			socketRef.current.on("message", ({ name, message }) => {
				NotificationManager.success(name + " completed " + message);
			})
			return () => socketRef.current.disconnect()
		},
		[]
	)

	const onTextChange = (e) => {
        const newState = {...state}
        newState[e.target.name] = e.target.value
		setState(newState)
        console.log(newState)
	}

	const onMessageSubmit = (e) => {
		const name = "Ony"
		const message = "Halo"
		socketRef.current.emit("message", { name, message })
		e.preventDefault()
		setState({ message: "", name })
	}

	const renderChat = () => {
		return chat.map(({ name, message }, index) => (
			<div key={index}>
				<h3>
					{name}: <span>{message}</span>
				</h3>
			</div>
		))
	}

	return (
		<div className="card">
			<form onSubmit={onMessageSubmit}>
				<h1>Messenger</h1>
				<div className="name-field">
					<input name='name' type="text" onChange={(e) => onTextChange(e)} value={state.name} />
				</div>
				<div>
					<input name='message' type="text" onChange={(e) => onTextChange(e)}
						value={state.message} />
				</div>
				<button>Send Message</button>
			</form>
			<div className="render-chat">
				<h1>Chat Log</h1>
				{renderChat()}
			</div>
            <NotificationContainer/>
		</div>
	)
}
