import React from 'react'
import TeamCard from '../components/atoms/TeamCard'

export default function Team() {
    return (
        <div>
            <div className="row">
                <div className="col-lg-3">
                <TeamCard />
                </div>
                <div className="col-lg-3">
                <TeamCard />
                </div>
                <div className="col-lg-3">
                <TeamCard />
                </div>
                <div className="col-lg-3">
                <TeamCard />
                </div>
                <div className="col-lg-3">
                <TeamCard />
                </div>
            </div>
        </div>
    )
}
