import axios from 'axios';
import React, { Component } from 'react'
import DatePicker from "react-multi-date-picker";
import Swal from 'sweetalert2';
import io from 'socket.io-client'
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';

export default class TaskUrgent extends Component {

  socket = io.connect('http://localhost:4000')

  saveData = async () => {

    const data = {
      name: this.state.name,
      brandid: this.state.brandid,
      taskid: this.state.taskid,
      description: "-",
      start_date: this.state.date,
      qty: this.state.qty,
      status: this.state.status,
      id: this.state.userid
    }
    console.log(data)
    if(this.state.name == ""){
      Swal.fire({
        title: 'Error!',
        text: 'Nama task belum di isi',
        icon: 'error',
        confirmButtonText: 'Ok'
      })
    }

    if(this.state.brandid == ""){
      Swal.fire({
        title: 'Error!',
        text: 'Brand Belum di Pilih',
        icon: 'error',
        confirmButtonText: 'Ok'
      })
    }

    if(this.state.taskid == ""){
      Swal.fire({
        title: 'Error!',
        text: 'Jenis task belum di pilih',
        icon: 'error',
        confirmButtonText: 'Ok'
      })
    }

    if(this.state.userid.length == 0){
      Swal.fire({
        title: 'Error!',
        text: 'Belum ada user yang di pilih',
        icon: 'error',
        confirmButtonText: 'Ok'
      })
    }

    // console.log(data)

    const value = await axios.post('https://apidigimark.sandigroup.id/api/createsingleurgent', data)
    console.log(value)
      if(value){
        Swal.fire({
          title: 'Berhasil!',
          text: 'Task berhasil disimpan',
          icon: 'success',
          confirmButtonText: 'Ok'
        })
        this.props.buttonPress()
      }
  }

  state = {
    name: "",
    brandid: "",
    userid: [this.props.userid],
    description: "",
    date: "",
    status: "Urgent",
    qty: 1,
    taskid:"",
    info: {
      category: [],
      brand: [],
    },
  }

  getInfo = async () => {
    const d = new Date();
    let year = d.getFullYear();
    let day = this.pad(d.getDate());
    const month = this.pad(d.getMonth()+1);
    const date = year + "-" + month + "-" + day
    const value = await axios.get('https://apidigimark.sandigroup.id/api/info')
    this.setState({
      info: value.data,
      date: date
    })
    console.log(value)
  }

  componentDidMount = () => {
    this.getInfo()
  }

  handleBrandChange  = (e) => {
    let value = Array.from(e.target.selectedOptions, option=> option.value);
    this.setState({
      brandid: value
    })
  }

  handleUserChange = (e) => {
    let value = Array.from(e.target.selectedOptions, option=> option.value);
    this.setState({
      userid: value
    })
  }

  pad = (d) => {
    return (d < 10) ? '0' + d.toString() : d.toString();
  } 

  handleDateChange = (e) => {
    const day = this.pad(e.day)
    const year = e.year
    const month = this.pad(e.monthIndex+1)
    const data = year + "-" + month + "-" + day
    this.setState({
      date : data
    })
  }

  onchangeName = (e) => {
    this.setState({
      name: e.target.value
    })
  }
  onchangeQty = (e) => {
    this.setState({
      qty: e.target.value
    })
  }
  onchangeTask = (e) => {
    this.setState({
      taskid: e.target.value
    })
  }

  render() {
    return (
      <div className='centermon'>
        .
        <div className="centerss">
        <div className="row">
          <div className="col-2"></div>
          <div className="col-8">
          <div className="chart-card3">
            <div className="row">
            <div className="col-12">
            <input type="text" className='form-control mb-2' placeholder='Masukan Nama Task' onChange={this.onchangeName} />
            </div>
            <div className="col-12">
            <input type="number" className='form-control mb-2' placeholder='Masukan Jumlah Task' onChange={this.onchangeQty} />
            </div>
            <div className="col-12">
            <select class="form-control mb-2" aria-label="multiple select example" onChange={this.onchangeTask}>
              <option selected>Pilih Jenis Task</option>
              {this.state.info.category.map(e => {
                return <option value={e.id}>{e.name}</option>
              })}
            </select>
            </div>
            <div className="col-12">
            <select class="form-control mb-2" multiple aria-label="multiple select example" onChange={this.handleBrandChange}>
              {this.state.info.brand.map(e => {
                return <option value={e.id}>{e.name}</option>
              })}
            </select>
            </div>
            <div className="col-12">
            <DatePicker value={this.state.date} onChange={this.handleDateChange} />
            </div>
            </div>
              <div style={{display: "flex"}} >
              <button onClick={()=>{
                this.saveData()
              }} className='btn btn-primary ml-auto mr-2 mt-3'>Simpan Task</button>
              <button className='btn btn-danger mt-3' onClick={this.props.handler}>Batal</button>
              </div>
          </div>
          </div>
          <div className="col-2"></div>
        </div>
        </div>
      </div>
    )
  }
}
