import React from 'react'

export default function HeaderCard(props) {
    return (
        <div>
            <div className="head-text pl-3">
                <div className="title">
                    Wellcome, <strong>{props.name}</strong>
                    <div className="subtitle">
                    Semoga harimu menyenangkan
                </div>
                </div>
                <div className="img-head">
                <img src="https://www.pinclipart.com/picdir/big/123-1238449_a-calendar-for-every-need-illustration-clipart.png" width={'150px'} alt="" />
            </div>
            </div>
        </div>
    )
}
