import React from 'react'
import axios from 'axios'
import { useState, useEffect } from 'react'
import Notif from './atoms/Notif'

export default function Header() {

    const user = JSON.parse(localStorage.getItem('user'))

    const [isShow, setIsShow] = useState(false)


    return (
        <div class="navbar-custom">
                <ul class="list-unstyled topnav-menu float-right mb-0">
               
            {/* <Notif/> */}
            <li class="dropdown notification-list topbar-dropdown">
                <a onClick={()=>{
                    setIsShow(!isShow)
                }} class="nav-link dropdown-toggle nav-user me-0 waves-effect waves-light" data-bs-toggle="dropdown" role="button" aria-haspopup="false" aria-expanded="false">
                    <img src={user.profile_photo_url} alt="user-image" class="rounded-circle"/>
                    <span class="pro-user-name  ml-2 ms-1">
                        {user.name} <i class="mdi mdi-chevron-down"></i> 
                    </span>
                </a>
                <div class={`dropdown-menu dropdown-menu-end profile-dropdown ${isShow ? "show" : null}`}>  
    
                    <a href="contacts-profile.html" class="dropdown-item notify-item">
                        <i class="fe-user"></i>
                        <span>My Profile</span>
                    </a>

    
                    <div class="dropdown-divider"></div>
    
                    <a href="auth-logout.html" class="dropdown-item notify-item">
                        <i class="fe-log-out"></i>
                        <span>Logout</span>
                    </a>
    
                </div>
            </li>
                </ul>

                <div class="logo-box">
                    <a href="index.html" class="logo logo-dark text-center">
                        <span class="logo-lg">
                            <img src="assets/images/logo-dark.png" alt="" height="16"/>
                        </span>
                        <span class="logo-sm">
                            <img src="assets/images/logo-sm.png" alt="" height="24"/>
                        </span>
                    </a>
                    <a href="index.html" class="logo logo-light text-center">
                        <span class="logo-lg">
                            <img src="assets/images/logo-light.png" alt="" height="16"/>
                        </span>
                        <span class="logo-sm">
                            <img src="assets/images/logo-sm.png" alt="" height="24"/>
                        </span>
                    </a>
                </div>

                <ul class="list-unstyled topnav-menu topnav-menu-left mb-0">
                    <li>
                        <button class="button-menu-mobile disable-btn waves-effect">
                            <i class="fe-menu"></i>
                        </button>
                    </li>

                    <li>
                        <h4 class="page-title-main"></h4>
                    </li>
        
                </ul>

            </div>
    )
}
