import React from 'react'
import { useParams } from 'react-router-dom'
import UserDashboard from '../Content/UserDashboard'

export default function User(props) {
    const {id} = useParams()
    console.log(id)
    return (
        <>
        <UserDashboard id={id} />
        </>
    )
}
