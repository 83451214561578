import React, { Component } from 'react'
import axios from 'axios';

export default class Notif extends Component {

    state= {
        noread : 0,
        isShow: false,
        notif:[],
        idnotread: [],
    }

    
    getNotif = async () => {
        const userid= 1;
        const notread = []
        const value = await axios.get('http://localhost:8000/api/notif')
        value.data.forEach(element => {
            if(element.user.some(person => person.user_id !== 1)){
                alert('ada')
            } else{
                // this.setState({
                //     noread: this.state.noread + 1,
                // })
            }
        });
        this.setState({
            idnotread: notread,
            notif: value.data
        })
    }

    componentDidMount = () => {
        this.getNotif()
    }

    render() {
        return (
            <li class="dropdown notification-list topbar-dropdown">
            <a onClick={()=>{
                console.log(this.state.idnotread)
                this.setState({
                    isShow : !this.state.isShow
                })
            }} class="nav-link dropdown-toggle waves-effect waves-light" data-bs-toggle="dropdown" role="button" aria-haspopup="false" aria-expanded="false">
                <i class="fe-bell noti-icon"></i>
                {this.state.noread == 0 ? null : <span class="badge bg-danger rounded-circle noti-icon-badge">{this.state.noread}</span>}
            </a>
            <div class={`dropdown-menu dropdown-menu-end dropdown-lg ${this.state.isShow ? "show" : null}`} style={{position: 'absolute', inset: '0px 0px auto auto', margin:' 0px', transform: 'translate(0px, 72px)'}}>

                <div class="dropdown-item noti-title">
                    <h5 class="m-0">
                        <span class="float-end">
                        </span>Notification
                    </h5>
                </div>

                <div class="noti-scroll" data-simplebar>

                    {this.state.notif.map(e => {
                        return (<a href="javascript:void(0);" class="dropdown-item notify-item active">
                        <div class="notify-icon">
                            <img src={e.users.profile_photo_url} class="img-fluid rounded-circle" alt="" /> </div>
                        <p class="notify-details">{e.users.name} {e.message}</p>
                        <p class="text-muted mb-0 user-msg">
                            <small>1 jam yang lalu</small>
                        </p>
                    </a>)
                    })}

                </div>

                <a href="javascript:void(0);" class="dropdown-item text-center text-primary notify-item notify-all">
                    View all
                    <i class="fe-arrow-right"></i>
                </a>

            </div>
        </li>
        )
    }
}
