import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { useEffect } from 'react';
import { useState } from 'react';
import axios from 'axios';
import { Bar } from 'react-chartjs-2';
import faker from 'faker';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function ChartDashboard2() {

  const [datasets, setdatasets] = useState([
    {
      label: 'Laporan',
      data: [10,10,10,10,10,10,10,10,10,10],
      backgroundColor: 'rgba(255, 99, 132, 0.5)',
    },
  ],)
  const[labels, setLabels] = useState(['January', 'February', 'March', 'April', 'May', 'June', 'July'])
  
  const getData = () => {
    console.log('a')
    axios.get('http://localhost:8000/api/task?month=-12-&laporan=true').then(result => {
      const data = Object.keys(result.data)
      const data_besar = data.splice(0, 10)
      console.log(data_besar)
      const data2 = Object.values(result.data)
      const data2_besar = data2.splice(0,10)
      console.log(data2)
      console.log('aaaa')
      setdatasets([
        {
          label: 'Laporan Bulan Desember',
          data: data2,
          backgroundColor: ['rgb(0, 143, 251)','rgb(254, 176, 25'],
        },
      ])
      setLabels(data)
      })
  }

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: 'top',
      },
      title: {
        display: true,
        text: 'Laporan Bulan Desember',
      },
    },
  };
  
  
  const data = {
    labels,
    datasets: datasets
  };

  useEffect(() => {
    getData()
  }, [])

  return (
      <div className="chart-card">
      <Bar options={options} data={data} />
      </div>
  );
}
