import React, { Component } from "react";
import Chart from "react-apexcharts";

class ChartDashboard extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="chart-card">
        <div className="mixed-chart">
            <Chart
              options={this.props.options}
              series={this.props.series}
              type="bar"
              height={"300px"}
              width="100%"
            />
          </div>
      </div>
    );
  }
}

export default ChartDashboard