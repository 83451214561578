import React from 'react'

export default function InfoCard(props) {
    return (
                <div class="info-wrapper mt-2">
                    <div className="info-des">
                        <div className="head-info-text">
                            {props.title}
                        </div>
                        <div className="sub-info-text">
                            {props.subtitle}
                        </div>
                    </div>
                    <div className={`number-des ${props.color}`}>
                        {props.number}
                    </div>
                </div>
    )
}
