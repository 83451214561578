import React from 'react'

export default function TeamCard() {
    return (
        <div>
            <div className="team-card">
                <div className="header">

                </div>
                <div className="img-card">
                <img src="https://media-assets-ggwp.s3.ap-southeast-1.amazonaws.com/2020/01/jutsu-naruto-640x360.jpg" className='profile' alt="" />
                </div>
              <div className="const-card p-2">
              <div className="img-card">
                   <div className="text-center">
                   <div className="title">Aji Bayu Nugroho</div>
                    <div className="subtitle">Web & SEO Specialist</div>
                   </div>
               </div>
               <div className="img-card my-3">
                   <button className='btn btn-danger mr-2'><i className='fas fa-user'></i> Profile</button>
                   <button className='btn btn-info'>Timeline</button>
               </div>
              </div>
            </div>
        </div>
    )
}
