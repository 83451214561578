import InfoCard from '../components/atoms/InfoCard'
import ChartDashboard from '../components/atoms/ChartDashboard'
import axios from 'axios'
import React, { Component } from 'react'
import Donut from '../components/atoms/Donut'
import { Table } from 'react-bootstrap'
import Task from './Task'
import { fa } from 'faker/lib/locales'
import TaskSingle from './TaskSingle'
import HeaderCard from '../components/atoms/HeaderCard'
import { Link } from 'react-router-dom'


export default class Dashboard extends Component {

    state={
        thismonth:"",
        completed:"",
        release:"",
        pending:"",
        thisday: "",
        task: [{
            name:'',
            start_date:'',
            status: '',
            brand:{
                name:""
            },
            category:{
                name: ""
            },
            users:[{
                name:"",
                profile_photo_url: ""
            }]
        }],
        month:"",
        year: "",
        brandLabel: [],
        brandCount: [],
        isVisible: false,
        isVisible2: false,
        userlist: [{
            id:"",
            name:"",
            profile_photo_url:"",
            role:{
                name:""
            },
        }]
    }

    getData = async (year, month, day) => {
        // console.log(year + " " + month)
        const result = await axios.get(`https://apidigimark.sandigroup.id/api/task?year=${year}&month=${month}&day=${day}&dashboard=true`)
        const data = Object.values(result.data)
        const series = []
        const uniqueCount = result.data.taskbrand
        const count = {};
        console.log(result.data.task)
        uniqueCount.forEach(function(i) { count[i] = (count[i]||0) + 1;});
        if(data.length == 0){
            alert('Laporan tidak ditemukan')
        }else{
            data.map(x => {
                series.push(parseInt(x))
            })
            this.setState({
                thismonth: result.data.thismonth,
                completed: result.data.completed,
                release: result.data.release,
                pending: result.data.pending,
                thisday: result.data.thisday,
                task: result.data.task,
                brandCount: Object.values(count),
                brandLabel: Object.keys(count)
            })
        } 
    }

    getSpecificData = async (year, month) => {
        // console.log(year + " " + month)
        const result = await axios.get(`https://apidigimark.sandigroup.id/api/task?year=${year}&month=${month}&dashboard=true`)
        const data = Object.values(result.data)
        const series = []
        const uniqueCount = result.data.taskbrand
        const count = {};
        uniqueCount.forEach(function(i) { count[i] = (count[i]||0) + 1;});
        if(result.data.taskbrand.length == 0){
            alert('Data tidak ditemukan')
        }else{
            data.map(x => {
                series.push(parseInt(x))
            })
            this.setState({
                thismonth: result.data.thismonth,
                completed: result.data.completed,
                release: result.data.release,
                pending: result.data.pending,
                task: result.data.task,
                brandCount: Object.values(count),
                brandLabel: Object.keys(count)
            })
        } 
    }

    getUserList = async () => {
        const result = await axios.get('https://apidigimark.sandigroup.id/api/user')
        this.setState({
            userlist : result.data
        })
    }

    componentWillUnmount = () => {
        localStorage.setItem('dashboard', JSON.stringify(this.state));
    }

    componentWillMount = () => {
        this.setState(JSON.parse(localStorage.getItem('dasboard')))
    }

    getDataFull = () => {
        const d = new Date();
        let year = d.getFullYear();
        let day = d.getDate();
        const month = d.getMonth();

        if(month+1 < 10){
            if(day < 10){
                this.getData(year, "-0"+(month+1)+'-', "0"+day)
            }else{
                this.getData(year, "-0"+(month+1)+'-', day)
            }
        }else{
            if(day < 10){
                this.getData(year, "-"+(month+1)+"-", "0"+day)
            }else{
                this.getData(year, "-"+(month+1)+"-", day)
            }

        }
    }

    componentDidMount = () => {
        this.getDataFull()
        this.getUserList()
    }

    handleChangeMonth = (e) => {
        this.setState({
            month: e.target.value
        })
    }
    handleChangeYear = (e) => {
        this.setState({
            year: e.target.value
        })
    }



    render() {
        return (
            <div>
                <div className="row">
                <div className="col-lg-6"></div>
                <div className="col-lg-6">
                    <div className="row">
                        <div className="col-lg-4">
                        <select class="form-control" aria-label="Default select example" onChange={this.handleChangeYear}>
                        <option selected>Tahun</option>
                        <option value="2021">2021</option>
                        <option value="2022">2022</option>
                        </select>
                        </div>
                        <div className="col-lg-4">
                        <select class="form-control" aria-label="Default select example" onChange={this.handleChangeMonth}>
                        <option selected>Bulan</option>
                        <option value="-01-">Januari</option>
                        <option value="-02-">Februari</option>
                        <option value="-03-">Maret</option>
                        <option value="-04-">April</option>
                        <option value="-05-">Mei</option>
                        <option value="-06-">Juni</option>
                        <option value="-07-">Juli</option>
                        <option value="-08-">Agustus</option>
                        <option value="-09-">September</option>
                        <option value="-10-">Oktober</option>
                        <option value="-11-">November</option>
                        <option value="-12-">Desember</option>
                        </select>
                        </div>
                        <div className="col-lg-4">
                        <button className='btn btn-danger btn-block' onClick={()=>{
                            this.getSpecificData(this.state.year, this.state.month)
                        }}>Submit</button>
                        </div>
                    </div>
                </div>
                </div>
               <div className="row">
               <div className="col-lg-12">
                <div className="chart-card2 mt-3">
                    <HeaderCard name={JSON.parse(localStorage.getItem('user')).name}/>
                </div>
                </div>
                   <div className="col-lg-9">

                <div className="row ">
                    <div className="col-lg-4">
                        
                    <InfoCard color="color-blue" title="Total Task" subtitle="Total task bulan ini" number={this.state.thismonth} />
                    </div>
                    <div className="col-lg-4">

                    <InfoCard color="color-green" title="Task Hari Ini" subtitle="Total task hari ini" number={this.state.thisday} />
                    </div>
                    <div className="col-lg-4">

                    <InfoCard color="color-red" title="Belum Dikerjakan" subtitle="Total task belum di kerjakan" number={this.state.release} />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-6">
                    <Donut options={{labels: ['Task belum dikerjakan','Task sudah dikerjakan', 'Pending']}} series={[this.state.release,this.state.completed, this.state.pending]}/>
                    </div>
                    <div className="col-6">
                    <ChartDashboard 
                    series={[
                        {
                        name: "series-1",
                        data: this.state.brandCount
                        }
                    ]}
                    options={{
                        chart: {
                        id: "basic-bar"
                        },
                        xaxis: {
                        categories: this.state.brandLabel
                        }
                    }} />
                    </div>
                </div>
                <div className="mt-3">
                    <div className="chart-card">
                    {JSON.parse(localStorage.getItem('user')).role_id == 1 ? <>
                    <button className='btn btn-success mb-2 mr-2' onClick={()=>{
                        this.setState({
                            isVisible2: true
                        })
                    }}>Create Single Task</button>
                    <button className='btn btn-success mb-2 mr-2' onClick={()=>{
                        this.setState({
                            isVisible: true
                        })
                    }}>Create Multiple Task</button>
                    </> : null}
                    <Table striped bordered hover>
                    <thead>
                        <tr>
                        <th>Nama Task</th>
                        <th>Jenis Task</th>
                        <th>Brand</th>
                        <th>User</th>
                        <th>Tanggal Mulai</th>
                        <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.task.map(task => {
                            return (
                                <tr>
                                <td>{task.name}</td>
                                <td>{task.category.name}</td>
                                <td>{task.brand.name}</td>
                                <td style={{width: "20%"}}>
                                <div className="avatar-group">
                                {task.users.map(e => {
                                    return (
                                        <a class="avatar-group-item">
                                                    <img src={e.profile_photo_url} class="rounded-circle avatar-sm" alt="friend" />
                                        </a>
                                    )
                                })}
                                </div>
                                </td>
                                <td>{task.start_date}</td>
                                <td>{
                                task.status == 'Urgent' ? <span className='badge bg-danger'>{task.status}</span> 
                                : task.status == 'Release' ? <span className='badge bg-purple'>{task.status}</span>
                                : task.status == 'Work In Proggress' ? <span className='badge bg-info'>{task.status}</span>
                                : task.status == 'pending' ? <span className='badge bg-warning'>{task.status}</span>
                                : <span className='badge bg-success'>{task.status}</span>
                                }</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
                    </div>
                </div>
                {this.state.isVisible ? <Task buttonPress={()=>{
                    this.getDataFull()
                }} handler={()=>{
                    this.setState({
                        isVisible: false
                    })
                }}/> : null }
                {this.state.isVisible2 ? <TaskSingle buttonPress={()=>{
                    this.getDataFull()
                }} handler={()=>{
                    this.setState({
                        isVisible2: false
                    })
                }}/> : null }
                   </div>
                   <div className="col-lg-3">
                       <div className="chart-card mt-2">
                           <h4>Team List</h4>
                           {this.state.userlist.map(e => {
                               return (
                                  <>
                                   <hr />
                                   <Link style={{color:'#666666'}} to={`/dashboard/${e.id}`}>
                                   <div className="friend-wrapper align-items-center d-flex mt-2">
                                        <div className="friend-img mr-2">
                                            <img src={e.profile_photo_url} style={{width: "40px", height: "40px", borderRadius: "50px"}} alt="" />
                                        </div>
                                        <div className="friend-info">
                                            <div className="name"><b>{e.name}</b></div>
                                            {e.role.name}
                                        </div>
                                    </div>
                                   </Link>
                                  </>
                               )
                           })}
                       </div>
                   </div>
               </div>
            </div>
        )
    }
}
