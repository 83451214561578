import React from 'react'

export default function InfoCard2(props) {
    return (
                <div class="info-wrapper2 mb-2">
                    <div className={`number-des ${props.color} mr-2`}>
                        {props.number}
                    </div>
                    <div className="info-des">
                        <div className="head-info-text">
                            {props.title}
                        </div>
                        <div className="sub-info-text">
                            {props.subtitle}
                        </div>
                    </div>
                </div>
    )
}
