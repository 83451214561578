import Login from './Content/Login';
import React, { Component } from 'react'
import { app } from './services/firebase';
import Container from './Content/Container';
import { getMessaging, getToken } from "firebase/messaging";
import TestChat from './Content/TestChat';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';


export default class App extends Component {

  state={
    isLogin : localStorage.getItem('user') ? true : false
  }

  getData = () => {

  }

  componentDidMount = () => {
  }

  render() {
    return (
      <>
      {this.state.isLogin? <Container /> : <Login/>}
      {/* <TestChat/> */}
      <NotificationContainer/>
      </>
    )
  }
}