import axios from 'axios'
import React, { Component } from 'react'
import { useState } from 'react'

export default function TaskAssignCard(props) {

    const [isLoading, setIsloading] = useState(false)

    return (
        <>
           {props.isempty? 
           <>
           <div className="content-card2">
            <div className="empty">
                Tidak ada task ditemukan
            </div>
            </div>
           </> 
           : 
           <><div className="content-card">
                <div className="project">
                    <div className="title">{props.brand? props.name + "-" +props.brand : props.name}</div>
                    <div className="date">{props.status? <span className='badge badge-purple'>{props.status}</span> : <span><i className='far fa-calendar-alt mr-1 mt-1'></i>{props.tgl}</span> }</div>
                </div>
                <div className="action">
                    <a onClick={()=>{
                        props.handler()
                        setIsloading(true)
                    }}>
                    {isLoading ? <div class="spinner-border text-info" style={{width:" 22px", height: "22px"}} role="status"><span class="sr-only">Loading...</span></div> : <img src="https://upload.wikimedia.org/wikipedia/commons/6/62/Font_Awesome_5_regular_check-circle.svg" width={"30px"} className='filter-grey' alt="" />} 
                    </a>
                </div>
            </div>
            <hr /></>}
           </>
    )
}
