import axios from 'axios'
import React, { Component } from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

export default function Login() {

    let navigate = useNavigate()

    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")

    const onchangeEmail = (e) => {
       setUsername(e.target.value)
    }

    const onchangePassword = (e) => {
        setPassword(e.target.value)
    }

    const logins = async () => {
        const data = {
            username: username,
            password: password
        }
        const result = await axios.post('https://apidigimark.sandigroup.id/api/login', data)
        console.log(result)
        if(result.status == 200){
            localStorage.setItem("user", JSON.stringify(result.data));
            window.location.reload()
        }
    }

    const getData = () => {
        const a = localStorage.getItem("user")
        console.log(JSON.parse(a))
    }

    return (
        <div className="cont">
            <div className='login-b'>
        <div class="card">
                   <div class="card-body p-4">
                       
                       <div class="text-center mb-4">
                           <h4 class="text-uppercase mt-0">LOGIN</h4>
                       </div>

                           <div class="mb-3">
                               <label for="emailaddress" class="form-label">Username</label>
                               <input class="form-control" type="text" id="emailaddress" required="" placeholder="Enter your email" onChange={onchangeEmail} />
                           </div>

                           <div class="mb-3">
                               <label for="password" class="form-label">Password</label>
                               <input class="form-control" type="password" required="" id="password" placeholder="Enter your password" onKeyUp={(e)=>{
                                   if(e.key == 'Enter'){
                                       logins()
                                   }
                               }} onChange={onchangePassword}/>
                           </div>

                           <div class="mb-3 d-grid text-center">
                               <button class="btn btn-primary btn-block btn-rounded" type="submit" onClick={logins}> Log In </button>
                           </div>
                           {/* <div class="mb-3 d-grid text-center">
                               <button class="btn btn-primary btn-block" type="submit" onClick={getData}> Get Data </button>
                           </div> */}

                   </div>
            </div>
        </div>
        </div>
    )
}