// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBHEW6zc5v7DqTTQdr5Mds4uy9tsgh9xD4",
  authDomain: "digimark-75909.firebaseapp.com",
  projectId: "digimark-75909",
  storageBucket: "digimark-75909.appspot.com",
  messagingSenderId: "577863540103",
  appId: "1:577863540103:web:2a4644b0001e166e24c722",
  measurementId: "G-NHH9LZ6NLY"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);