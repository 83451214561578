import React, { Component } from 'react'
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';
import Report from '../Content/Report';
import { Routes, Route, Link } from "react-router-dom";
import Dashboard from '../Content/Dashboard';
import User from '../components/User';
import Task from '../Content/Task';
import TaskSingle from '../Content/TaskSingle';
import Login from '../Content/Login';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import Team from './Team';

export default class Container extends Component {


    render() {
        return (
            <div id="wrapper">
            <Header/>
            <Sidebar/>
            <div class="content-page mb-5">
                <div class="content">
                    <div class="container-fluid">
                    <Routes>
                      <Route path="/" element={<Dashboard />} />
                      <Route path="/dashboard/:id" element={<User/>} />
                      <Route path="/laporan" element={<Report />} />
                      <Route path="/task" element={<Task />} />
                      <Route path="/tasksingle" element={<TaskSingle/>} />
                      <Route path="/team" element={<Team/>} />
                    </Routes>
                    </div>
                </div>
                <NotificationContainer/>
                <Footer />
            </div>
          </div>
        )
    }
}
