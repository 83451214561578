import axios from 'axios'
import React, { Component } from 'react'
import DatePicker from 'react-multi-date-picker'
import TaskAssignCard from './TaskAssignCard'

export default class TaskThisDay extends Component {

    constructor(props){
        super(props)
    }


    state = {
        value : new Date(),
        task : [],
    }

    pad = (d) => {
        return (d < 10) ? '0' + d.toString() : d.toString();
    }

    getTaskDay = async (data) => {
        const value = await axios.get(`http://localhost:8000/api/taskuser?date=${data}&id=${this.props.id}`)
        console.log(value)
        this.setState({
            task : value.data
        })
    }

    componentDidMount = () => {
        const d = new Date();
        let year = d.getFullYear();
        let day = this.pad(d.getDate());
        const month = this.pad(d.getMonth() + 1);
        const date = year + "-" + month + "-" + day

        this.getTaskDay(date)

        console.log(this.props)
    }

    render() {
        return (
        <div className='chart-card mb-3'>
            <div className="header-card">
                <div className="left">
                    <div className="left-bar3"></div>
                    <div className="title">Task Hari Ini</div>
                </div>
                <div className="right">
                    <DatePicker value={this.state.value} onChange={(e) => {
                        const day = this.pad(e.day)
                        const year = e.year
                        const month = this.pad(e.monthIndex+1)
                        const date = year + "-" + month + "-" + day
                        this.getTaskDay(date)
                    }} />
                </div>
            </div>
            {this.state.task.length == 0? <TaskAssignCard isempty={true} /> : this.state.task.map(e => {
                                   return e.status == "Release" ? <TaskAssignCard id={e.id} handler={this.props.handler} name={e.name} tgl={e.start_date} status={e.status} /> : null
                                })}
        </div>
        )
    }
}
