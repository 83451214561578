import React, { Component } from 'react';
import Chart from 'react-apexcharts'



class Donut extends Component {

    constructor(props) {
      super(props);
  
      this.state = {
        options: {labels: ['A', 'B', 'C', 'D', 'E']},
        series: [44, 55, 41, 17, 15],
        
      }
    }
  
    render() {
      return (
        <div className="chart-card">
          <Chart options={this.props.options} series={this.props.series} type="donut" height={"500px"} width="100%" />
        </div>
      );
    }
  }

  export default Donut