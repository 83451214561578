import React, { Component } from 'react'
import ChartDashboard from '../components/atoms/ChartDashboard'
import ChartDoughnut from '../components/atoms/ChartDoughnut'
import ChartDashboard2 from '../components/atoms/ChartDashboard2'
import axios from 'axios'
import Donut from '../components/atoms/Donut'

export default class Report extends Component {

    state={
        isLoaded : false,
        topBar : {
            data: [],
            labels: [],
        },
        donut: {
            data: [],
            labels: []
        },
        month:"",
        year:"",
    }

    getSpecificData = async () => {

    }

    getData = async (year, month) => {
        // console.log(year + " " + month)
        const result = await axios.get(`https://apidigimark.sandigroup.id/api/task?year=${year}&month=${month}&laporan=true`)
        const data = Object.values(result.data)
        const series = []
        if(data.length == 0){
            alert('Laporan tidak ditemukan')
        }else{
            data.map(x => {
                series.push(parseInt(x))
            })
            this.setState({
                topBar: {
                    data: Object.values(result.data),
                    labels: Object.keys(result.data),
                },
                donut: {
                    data: series,
                    labels: Object.keys(result.data),
                },
                isLoaded: true
            })
        } 
    }

    handleChangeMonth = (e) => {
        this.setState({
            month: e.target.value
        })
    }
    handleChangeYear = (e) => {
        this.setState({
            year: e.target.value
        })
    }

    componentDidMount = () => {
    }

    render() {
        return (
            
            <div>
            <div className="row">
                <div className="col-lg-6"></div>
                <div className="col-lg-6">
                    <div className="row">
                        <div className="col-lg-4">
                        <select class="form-control" aria-label="Default select example" onChange={this.handleChangeYear}>
                        <option selected>Tahun</option>
                        <option value="2021">2021</option>
                        <option value="2022">2022</option>
                        </select>
                        </div>
                        <div className="col-lg-4">
                        <select class="form-control" aria-label="Default select example" onChange={this.handleChangeMonth}>
                        <option selected>Bulan</option>
                        <option value="-01-">Januari</option>
                        <option value="-02-">Februari</option>
                        <option value="-03-">Maret</option>
                        <option value="-04-">April</option>
                        <option value="-05-">Mei</option>
                        <option value="-06-">Juni</option>
                        <option value="-07-">Juli</option>
                        <option value="-08-">Agustus</option>
                        <option value="-09-">September</option>
                        <option value="-10-">Oktober</option>
                        <option value="-11-">November</option>
                        <option value="-12-">Desember</option>
                        </select>
                        </div>
                        <div className="col-lg-4">
                        <button className='btn btn-danger btn-block' onClick={()=>{
                            this.getData(this.state.year, this.state.month)
                        }}>Laporan</button>
                        </div>
                    </div>
                </div>
            </div>
            {this.state.isLoaded ? <div className="row mt-2">
            <div className="col-6">
            <ChartDashboard 
            series={[
                {
                name: "series-1",
                data: this.state.topBar.data.slice(0,10)
                }
            ]}
            options={{
                chart: {
                  id: "basic-bar"
                },
                xaxis: {
                  categories: this.state.topBar.labels.slice(0,10)
                }
              }} />
            </div>
            <div className="col-6">
            <ChartDashboard 
            series={[
                {
                name: "series-1",
                data: this.state.topBar.data.slice(10)
                }
            ]}
            options={{
                chart: {
                  id: "basic-bar"
                },
                xaxis: {
                  categories: this.state.topBar.labels.slice(10)
                }
              }} />
            </div>
            <div className="col-6 mt-2">
            <Donut options={{labels: this.state.donut.labels}} series={this.state.donut.data}/>
            </div>
            </div>: null}
            </div>
        )
    }
}
